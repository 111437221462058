import React from 'react';
import StepperComponent from './StepperComponent';
import { Container } from '@mui/material';

const App = () => {
  return (
    <Container>
      <StepperComponent />
      <div style={{position: 'absolute',
    bottom: '1rem',
    right: '1rem'}}>v1.0.0</div>
    </Container>
  );
};

export default App;
