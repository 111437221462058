import React, { useState, useRef } from 'react';
import { toSvg, toPng } from 'html-to-image';
import download from 'downloadjs';
import { Box, Step, StepLabel, Stepper, Button, Typography } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

const steps = ['Material', 'Size', 'Accessories', 'Icons', 'Panel', 'Frame'];

const moduleOptions = [
  { label: '2 module', image: 'https://via.placeholder.com/300?text=2+module', className: 'module-display module-2 module-black module-fsilver', subclassName: 'sub-module-2', maxNodeSize:2 },
  { label: '4 module', image: 'https://via.placeholder.com/300?text=4+module', className: 'module-display module-4 module-black module-fsilver', subclassName: 'sub-module-4', maxNodeSize:4 },
  { label: '6 module', image: 'https://via.placeholder.com/300?text=6+module', className: 'module-display module-6 module-black module-fsilver', subclassName: 'sub-module-6', maxNodeSize:6 },
  { label: '8 module', image: 'https://via.placeholder.com/300?text=8+module', className: 'module-display module-8 module-black module-fsilver', subclassName: 'sub-module-8', maxNodeSize:6 },
  { label: '12 module', image: 'https://via.placeholder.com/300?text=12+module', className: 'module-display module-12 module-black module-fsilver', subclassName: 'sub-module-12', maxNodeSize:6 },
];

const colors = [
  "#000000","#a8a8a8","#ffffff"
];

const accessories = {
  '2 module': [
    {name: '2 Switch', nodeSize: 1, className:'switch',nodes: [{nodeClasses:['switch2Image switch2Imagesubnode1','switch2Image switch2Imagesubnode2']}]},
    {name: 'Fan', nodeSize: 2, className:'switch', nodes: [{nodeClasses:['switch2ImageFan switch2ImageFansubnode1']},{nodeClasses:['switch2ImageDimmer']}]},
    {name: '4 Switch', nodeSize: 2, className:'scene4',nodes: [{nodeClasses:['switch2Image scene4-1subnode1','switch2Image scene4-1subnode2']},{nodeClasses:['switch2Image scene4-2subnode1','switch2Image scene4-2subnode2']}]},
    {name: 'Curtain', nodeSize: 1, className:'switch', nodes: [{nodeClasses:['curtainImage']}]},
    {name: '6 Amp Socket', nodeSize: 2, className:'switch', nodes: [{nodeClasses:['sixampImage']}]},
    {name: '16 Amp Socket', nodeSize: 2, className:'switch', nodes: [{nodeClasses:['socket16amp']}]},
    {name: 'USB (A-C type)', nodeSize: 2, className:'switch', nodes: [{nodeClasses:['socket16amp']}]},
  ],
  '4 module': [
    {name: '4 Switch', nodeSize: 2, className:'scene4',nodes: [{nodeClasses:['switch2Image scene44-1subnode1','switch2Image scene44-1subnode2']},{nodeClasses:['switch2Image scene44-2subnode1','switch2Image scene44-2subnode2']}]},
    {name: '4 Switch + 1 Fan', nodeSize: 4, className:'scene4',nodes: [{nodeClasses:['switch2Image scene4f-1subnode1','switch2Image scene4f-1subnode2']},{nodeClasses:['switch2Image scene4f-2subnode1','switch2Image scene4f-2subnode2']},{nodeClasses:['switch2ImageFan switch2ImageFansubnode1 scene4f-subnodef']},{nodeClasses:['switch2ImageDimmer scene4f-subnoded']}]},
    {name: '6 Switch', nodeSize: 3, className:'scene4',nodes: [{nodeClasses:['switch2Image scene6-1subnode1','switch2Image scene6-1subnode2']},{nodeClasses:['switch2Image scene6-2subnode1','switch2Image scene6-2subnode2']},{nodeClasses:['switch2Image scene6-3subnode1','switch2Image scene6-3subnode2']}]}
  ],
  '6 module': [
    {name: '10 Switch', nodeSize: 5, className:'scene6',nodes: [{nodeClasses:['switch2Image scene610-1subnode1','switch2Image scene610-1subnode2']},{nodeClasses:['switch2Image scene610-2subnode1','switch2Image scene610-2subnode2']},{nodeClasses:['switch2Image scene610-3subnode1','switch2Image scene610-3subnode2']},{nodeClasses:['switch2Image scene610-4subnode1','switch2Image scene610-4subnode2']},{nodeClasses:['switch2Image scene610-5subnode1','switch2Image scene610-5subnode2']}]},
    {name: '12 Switch', nodeSize: 6, className:'scene6',nodes: [{nodeClasses:['switch2Image scene610-1subnode1','switch2Image scene610-1subnode2']},{nodeClasses:['switch2Image scene610-2subnode1','switch2Image scene610-2subnode2']},{nodeClasses:['switch2Image scene610-3subnode1','switch2Image scene610-3subnode2']},{nodeClasses:['switch2Image scene610-4subnode1','switch2Image scene610-4subnode2']},{nodeClasses:['switch2Image scene610-5subnode1','switch2Image scene610-5subnode2']},{nodeClasses:['switch2Image scene610-6subnode1','switch2Image scene610-6subnode2']}]},
    {name: '4 Switch + 1 Fan', nodeSize: 4, className:'scene6',nodes: [{nodeClasses:['switch2Image scene4f-1subnode1','switch2Image scene4f-1subnode2']},{nodeClasses:['switch2Image scene4f-2subnode1','switch2Image scene4f-2subnode2']},{nodeClasses:['switch2ImageFan switch2ImageFansubnode1 scene4f-subnodef']},{nodeClasses:['switch2ImageDimmer scene4f-subnoded']}]},
    {name: '6 Switch', nodeSize: 3, className:'scene6',nodes: [{nodeClasses:['switch2Image scene6-1subnode1','switch2Image scene6-1subnode2']},{nodeClasses:['switch2Image scene6-2subnode1','switch2Image scene6-2subnode2']},{nodeClasses:['switch2Image scene6-3subnode1','switch2Image scene6-3subnode2']}]},
    {name: '8 Switch', nodeSize: 4, className:'scene6',nodes: [{nodeClasses:['switch2Image scene610-1subnode1','switch2Image scene610-1subnode2']},{nodeClasses:['switch2Image scene610-2subnode1','switch2Image scene610-2subnode2']},{nodeClasses:['switch2Image scene610-3subnode1','switch2Image scene610-3subnode2']},{nodeClasses:['switch2Image scene610-4subnode1','switch2Image scene610-4subnode2']}]}
  ],
  '8 module': [
    {name:'10 Switch', nodeSize: 5, className:'scene6',nodes: [{nodeClasses:['switch2Image scene610-1subnode1','switch2Image scene610-1subnode2']},{nodeClasses:['switch2Image scene610-2subnode1','switch2Image scene610-2subnode2']},{nodeClasses:['switch2Image scene610-3subnode1','switch2Image scene610-3subnode2']},{nodeClasses:['switch2Image scene610-4subnode1','switch2Image scene610-4subnode2']},{nodeClasses:['switch2Image scene610-5subnode1','switch2Image scene610-5subnode2']}]},
    {name: '8 Switch + 1 Fan', nodeSize: 6, className:'scene6',nodes: [{nodeClasses:['switch2Image scene610-1subnode1','switch2Image scene610-1subnode2']},{nodeClasses:['switch2Image scene610-2subnode1','switch2Image scene610-2subnode2']},{nodeClasses:['switch2Image scene610-3subnode1','switch2Image scene610-3subnode2']},{nodeClasses:['switch2Image scene610-4subnode1','switch2Image scene610-4subnode2']},{nodeClasses:['switch2ImageFan switch2ImageFansubnode1 scene8f-subnodef']},{nodeClasses:['switch2ImageDimmer scene8f-subnoded']}]},
    {name: '4 Switch + 2 Fan', nodeSize: 6, className:'scene6',nodes: [{nodeClasses:['switch2Image scene610-1subnode1','switch2Image scene610-1subnode2']},{nodeClasses:['switch2Image scene610-2subnode1','switch2Image scene610-2subnode2']},{nodeClasses:['switch2ImageFan switch2ImageFansubnode1 scene8f1-subnodef']},{nodeClasses:['switch2ImageDimmer scene8f1-subnoded']},{nodeClasses:['switch2ImageFan switch2ImageFansubnode1 scene8f2-subnodef']},{nodeClasses:['switch2ImageDimmer scene8f2-subnoded']}]},
  ],
  '12 module': [
    {name:'10 Switch', nodeSize: 5, className:'scene6',nodes: [{nodeClasses:['switch2Image scene610-1subnode1','switch2Image scene610-1subnode2']},{nodeClasses:['switch2Image scene610-2subnode1','switch2Image scene610-2subnode2']},{nodeClasses:['switch2Image scene610-3subnode1','switch2Image scene610-3subnode2']},{nodeClasses:['switch2Image scene610-4subnode1','switch2Image scene610-4subnode2']},{nodeClasses:['switch2Image scene610-5subnode1','switch2Image scene610-5subnode2']}]},
    {name: '8 Switch + 1 Fan', nodeSize: 6, className:'scene6',nodes: [{nodeClasses:['switch2Image scene610-1subnode1','switch2Image scene610-1subnode2']},{nodeClasses:['switch2Image scene610-2subnode1','switch2Image scene610-2subnode2']},{nodeClasses:['switch2Image scene610-3subnode1','switch2Image scene610-3subnode2']},{nodeClasses:['switch2Image scene610-4subnode1','switch2Image scene610-4subnode2']},{nodeClasses:['switch2ImageFan switch2ImageFansubnode1 scene8f-subnodef']},{nodeClasses:['switch2ImageDimmer scene8f-subnoded']}]},
    {name: '4 Switch + 2 Fan', nodeSize: 6, className:'scene6',nodes: [{nodeClasses:['switch2Image scene610-1subnode1','switch2Image scene610-1subnode2']},{nodeClasses:['switch2Image scene610-2subnode1','switch2Image scene610-2subnode2']},{nodeClasses:['switch2ImageFan switch2ImageFansubnode1 scene8f1-subnodef']},{nodeClasses:['switch2ImageDimmer scene8f1-subnoded']},{nodeClasses:['switch2ImageFan switch2ImageFansubnode1 scene8f2-subnodef']},{nodeClasses:['switch2ImageDimmer scene8f2-subnoded']}]},
  ],
};

const icons = {
  'Decorative Lights': [
    './assets/Decorative%20Lights/1.svg',
    './assets/Decorative%20Lights/2.svg',
    './assets/Decorative%20Lights/3.svg',
    './assets/Decorative%20Lights/HL.svg',
    './assets/Decorative%20Lights/HL2.svg',
    './assets/Decorative%20Lights/HL3.svg',
    './assets/Decorative%20Lights/HL5.svg',
    './assets/Decorative%20Lights/HL6.svg',
    './assets/Decorative%20Lights/HL7.svg',
    './assets/Decorative%20Lights/HL8.svg',
    './assets/Decorative%20Lights/HL9.svg',
    './assets/Decorative%20Lights/HL10.svg',
    './assets/Decorative%20Lights/HL11.svg',
    './assets/Decorative%20Lights/HL12.svg',
    './assets/Decorative%20Lights/HL13.svg',
    './assets/Decorative%20Lights/HL15.svg',
    './assets/Decorative%20Lights/HL16.svg',
    './assets/Decorative%20Lights/HL17.svg',
    './assets/Decorative%20Lights/HL18.svg',
    './assets/Decorative%20Lights/Security%20Camera 1.svg',
    './assets/Decorative%20Lights/Security%20Camera.svg',
  ],
  'Home Appliances': [
      './assets/Home%20Appliances/1%20(1).svg',
      './assets/Home%20Appliances/2%20(1).svg',
      './assets/Home Appliances/3%20(1).svg',
      './assets/Home Appliances/4.svg',
      './assets/Home Appliances/5.svg',
      './assets/Home Appliances/6.svg',
      './assets/Home Appliances/7.svg',
      './assets/Home Appliances/8.svg',
      './assets/Home Appliances/9.svg',
      './assets/Home Appliances/10.svg',
      './assets/Home Appliances/11.svg',
      './assets/Home Appliances/12.svg',
      './assets/Home Appliances/13.svg',
      './assets/Home Appliances/14.svg',
      './assets/Home Appliances/15.svg',
      './assets/Home Appliances/16.svg',
      './assets/Home Appliances/18.svg',
      './assets/Home Appliances/19.svg',
      './assets/Home Appliances/Appliances-91.svg',
      './assets/Home Appliances/Appliances-92.svg',
      './assets/Home Appliances/Appliances-93.svg',
      './assets/Home Appliances/Appliances-94.svg',
      './assets/Home Appliances/Appliances-95.svg',
      './assets/Home Appliances/Appliances-96.svg',
      './assets/Home Appliances/Appliances-97.svg',
      './assets/Home Appliances/Appliances-98.svg',
      './assets/Home Appliances/Appliances-99.svg',
      './assets/Home Appliances/Appliances-100.svg',
      './assets/Home Appliances/Appliances-101.svg',
      './assets/Home Appliances/Appliances-102.svg',
      './assets/Home Appliances/Appliances-103.svg',
      './assets/Home Appliances/Appliances-104.svg',
      './assets/Home Appliances/Appliances-105.svg',
      './assets/Home Appliances/Appliances-106.svg'
    ],
   'Scene': [
    './assets/Scene/1.svg',
    './assets/Scene/2.svg',
    './assets/Scene/3.svg',
    './assets/Scene/4.svg',
    './assets/Scene/5.svg',
    './assets/Scene/6.svg',
    './assets/Scene/7.svg',
    './assets/Scene/8.svg',
    './assets/Scene/9.svg',
    './assets/Scene/10.svg',
    './assets/Scene/11.svg',
    './assets/Scene/12.svg',
    './assets/Scene/Scene-71.svg',
    './assets/Scene/Scene-72.svg',
    './assets/Scene/Scene-73.svg',
    './assets/Scene/Scene-74.svg',
    './assets/Scene/Scene-75.svg',
    './assets/Scene/Scene-76.svg',
    './assets/Scene/Scene-77.svg',
    './assets/Scene/Scene-78.svg',
    './assets/Scene/Scene-79.svg',
    './assets/Scene/Scene-80.svg',
    './assets/Scene/Scene-81.svg',
    './assets/Scene/Scene-82.svg',
    './assets/Scene/Scene-83.svg',
    './assets/Scene/Scene-84.svg',
    './assets/Scene/Scene-85.svg',
    './assets/Scene/Scene-86.svg',
    './assets/Scene/Scene-87.svg',
   ],
   'Necessity': [
    './assets/Necessity/AC.svg',
    './assets/Necessity/AC2.svg',
    './assets/Necessity/Coffee Mixer.svg',
    './assets/Necessity/Cooking Cooker.svg',
    './assets/Necessity/Fridge.svg',
    './assets/Necessity/Geyser.svg',
    './assets/Necessity/Hair Dryer.svg',
    './assets/Necessity/Kettle.svg',
    './assets/Necessity/Mixer.svg',
    './assets/Necessity/Mixer3.svg',
    './assets/Necessity/Mixer4.svg',
    './assets/Necessity/Oven2.svg',
    './assets/Necessity/Radio.svg',
    './assets/Necessity/Toaster.svg',
    './assets/Necessity/TV.svg',
    './assets/Necessity/Washing Machine.svg',
   ],
    'Light': [
      './assets/Lights/L1.svg',
      './assets/Lights/L2.svg',
      './assets/Lights/L3.svg',
      './assets/Lights/L4.svg',
      './assets/Lights/L5.svg',
      './assets/Lights/L6.svg',
      './assets/Lights/L7.svg',
      './assets/Lights/L8.svg',
      './assets/Lights/L9.svg',
      './assets/Lights/L10.svg',
      './assets/Lights/L11.svg',
      './assets/Lights/L12.svg',
      './assets/Lights/L13.svg',
      './assets/Lights/L14.svg',
      './assets/Lights/L15.svg',
      './assets/Lights/Lamp-11.svg',
      './assets/Lights/Lamp-12.svg',
      './assets/Lights/Lamp-13.svg',
      './assets/Lights/Lamp-14.svg',
      './assets/Lights/Lamp-15.svg',
      './assets/Lights/Lamp-16.svg',
      './assets/Lights/Lamp-17.svg',
      './assets/Lights/Lamp-18.svg',
      './assets/Lights/Lamp-19.svg',
      './assets/Lights/Lamp-20.svg',
      './assets/Lights/Lamp-21.svg',
      './assets/Lights/Lamp-22.svg',
      './assets/Lights/Lamp-23.svg',
      './assets/Lights/Lamp-24.svg',
      './assets/Lights/Lamp-25.svg',
      './assets/Lights/Lamp-26.svg',
      './assets/Lights/Lamp-27.svg',
    ],
    'COB Lighting': [
      './assets/COB Lighting/CL1.svg',
      './assets/COB Lighting/CL2.svg',
      './assets/COB Lighting/CL3.svg',
    ],
    'Fan': [
      './assets/Fans/F1.svg',
      './assets/Fans/F2.svg',
      './assets/Fans/F4.svg',
      './assets/Fans/F5.svg',
      './assets/Fans/F6.svg',
      './assets/Fans/F7.svg',
      './assets/Fans/F8.svg',
      './assets/Fans/F10.svg',
      './assets/Fans/F11.svg',
      './assets/Fans/F12.svg',
      './assets/Fans/F13.svg',
      './assets/Fans/F14.svg',
      './assets/Fans/F15.svg',
      './assets/Fans/F16.svg',
      './assets/Fans/F17.svg',
      './assets/Fans/F18.svg',
      './assets/Fans/F19.svg',
      './assets/Fans/F20.svg',
      './assets/Fans/F21.svg',
      './assets/Fans/Fan Default.svg',
    ],
    'BLDC Fan': [
      './assets/BLDC Fans/1.svg',
      './assets/BLDC Fans/2.svg',
      './assets/BLDC Fans/3.svg',
      './assets/BLDC Fans/4.svg',
      './assets/BLDC Fans/5.svg',
      './assets/BLDC Fans/6.svg',
      './assets/BLDC Fans/7.svg',
      './assets/BLDC Fans/8.svg',
      './assets/BLDC Fans/9.svg',
      './assets/BLDC Fans/10.svg',
      './assets/BLDC Fans/11.svg',
      './assets/BLDC Fans/12.svg',
      './assets/BLDC Fans/13.svg',
    ],
    'Numbers': [
      './assets/Numbers/0.svg',
      './assets/Numbers/1.svg',
      './assets/Numbers/1B.svg',
      './assets/Numbers/2.svg',
      './assets/Numbers/2B.svg',
      './assets/Numbers/3.svg',
      './assets/Numbers/3B.svg',
      './assets/Numbers/4.svg',
      './assets/Numbers/4B.svg',
      './assets/Numbers/5.svg',
      './assets/Numbers/5B.svg',
      './assets/Numbers/6.svg',
      './assets/Numbers/6B.svg',
      './assets/Numbers/7.svg',
      './assets/Numbers/7B.svg',
      './assets/Numbers/8.svg',
      './assets/Numbers/8B.svg',
      './assets/Numbers/9.svg',
      './assets/Numbers/9B.svg',
    ]
  };

  const DraggableIcon = ({ icon, index, onDrop }) => {
    const [{ isDragging }, drag] = useDrag(() => ({
      type: 'icon',
      item: { icon, index },
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
    }));
  
    return (
      <img
        ref={drag}
        src={icon}
        alt={`icon-${index}`}
        className={`w-1/2 ${isDragging ? 'opacity-50' : 'opacity-100'}`}
      />
    );
  };

  const DroppableAccessory = ({ subnode, category, accessory, indexToRemove, onDrop, activeStep=0 }) => {
    const [, drop] = useDrop(() => ({
      accept: 'icon',
      drop: (item) =>{
        let pnode = document.getElementById(`${subnode}-${indexToRemove}`);
        let node = document.getElementById(`${subnode}-${indexToRemove}-svg`);
        console.log(item, subnode, node);
        pnode.style.border = 'none';
       // node.style.backgroundImage = `url(${item.icon})`;
       // node.style.backgroundSize = '9mm';
        if (node) {
          fetch(item.icon)
  .then(response => response.text())
  .then(svgContent => {
      node.innerHTML = svgContent;
  });
          //node.innerHTML = item.icon.svg;
          // const parser = new DOMParser();
          // const svgDoc = parser.parseFromString(item.icon.svg, 'image/svg+xml');
          // const svgElement = svgDoc.documentElement;
          // node.appendChild(svgElement);
          const imgTag = node.querySelector('img');
          if (imgTag) {
            //imgTag.src = item.icon; // Set the src attribute of the img tag
          }
        }
        onDrop(category, accessory, indexToRemove, item.icon);
        
      }
      //onDrop(category, accessory, indexToRemove, item.icon),
    }));
  
    return (
            <>
             <div ref={drop} className={`${subnode}`} id={`${subnode}-${indexToRemove}`}>
            {activeStep == 3 && <DeleteIcon className='delete' onClick={()=>{ 
              let node = document.getElementById(`${subnode}-${indexToRemove}`);
              let cnode = document.getElementById(`${subnode}-${indexToRemove}-svg`);
              node.style.border = '1px solid #ffffff';
              node.style.backgroundImage = 'none';
              cnode.innerHTML='';
              }} fontSize="small" style={{bottom: '-10px',
    left: '-25px',
    color: 'white',
    position: 'relative'}}/>}
              <div id={`${subnode}-${indexToRemove}-svg`}></div>
              {/* <img src=""></img> */}
              </div>
              {/* <Button
          size="small"
          onClick={() =>{}}
          className="absolute bottom-0 right-0 bg-red-500 text-white p-1"
        > */}
          {/* <DeleteIcon fontSize="small" /> */}
        {/* </Button> */}
            </> 
    );
  };

const StepperComponent = () => {
  const divRef = useRef();
  const [activeStep, setActiveStep] = useState(0);
  const [selectedMaterial, setSelectedMaterial] = useState('');
  const [selectedModule, setSelectedModule] = useState('');
  const [selectedAccessories, setSelectedAccessories] = useState({
    '2 module': [],
    '4 module': [],
    '6 module': [],
    '8 module': [],
    '12 module': [],
  });
  const [accessoryIcons, setAccessoryIcons] = useState({});

  const handleDownload = async () => {
    if (divRef.current) {
      try {
        // Remove transform before capturing the SVG
        divRef.current.style.transform = 'none';
        let bg =   divRef.current.style.backgroundColor;
        divRef.current.style.backgroundColor = 'transparent';
        let border = divRef.current.style.border;
        divRef.current.style.border = '2px solid black';
        let bs = divRef.current.style.boxShadow;
        divRef.current.style.boxShadow = 'none';

        // Capture the SVG
        const svgData = await toSvg(divRef.current, { 
          width: divRef.current.offsetWidth / 0.75, 
          height: divRef.current.offsetHeight / 0.75,
        });

          // Capture the PNG
          const pngData = await toPng(divRef.current, { 
            width: divRef.current.offsetWidth / 0.75, 
            height: divRef.current.offsetHeight / 0.75,
          });

        // Reset transform to original value
        divRef.current.style.transform = 'scale(0.75)';
       // let bg =   divRef.current.style.backgroundColor;
        divRef.current.style.backgroundColor = bg;
      //  let border = divRef.current.style.border;
        divRef.current.style.border = border;
      //  let bs = divRef.current.style.boxShadow;
        divRef.current.style.boxShadow = bs;

        // Download the SVG
        download(svgData, 'my-div.svg');
         // Download the PNG
         download(pngData, 'my-div.png');
      } catch (error) {
        console.error('Error generating SVG:', error);
      }
    }
  };

  const handleNext = () => {
    if (activeStep === 0 && !selectedMaterial) {
      alert('Cannot proceed without selecting a material');
      return;
    }
    if (activeStep === 1 && !selectedModule) {
      alert('Cannot proceed without selecting a module');
      return;
    }
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
    setSelectedMaterial('');
    setSelectedModule('');
    setSelectedAccessories({
      '2 module': [],
      '4 module': [],
      '6 module': [],
      '8 module': [],
      '12 module': [],
    });
    setAccessoryIcons({});
  };

  const handleMaterialSelect = (material) => {
    setSelectedMaterial(material);
  };

  const handleModuleSelect = (module) => {
    setSelectedModule(module);
    setSelectedAccessories({
      '2 module': [],
      '4 module': [],
      '6 module': [],
      '8 module': [],
      '12 module': [],
    }); // Reset accessory selection when module changes
  };

  const handleAccessorySelect = (category, accessory) => {
        let totsize=0;
        let keys = Object.keys(selectedAccessories);
        for(let key of keys){
            if(selectedAccessories[key].length){
                for(let acc of selectedAccessories[key]){
                    totsize+=acc.nodeSize;
                }
            }
        }
        totsize+=accessory.nodeSize;
        console.log(totsize);
      if(totsize>moduleOptions.find(option => option.label === selectedModule).maxNodeSize){  
        alert('Max size limit for the module reached!!');
        return;
      } else {
        setSelectedAccessories((prevSelected) => {
            const categoryAccessories = prevSelected[category];
          //   if (categoryAccessories.includes(accessory)) {
          //     return {
          //       ...prevSelected,
          //       [category]: categoryAccessories.filter((item) => item !== accessory),
          //     };
          //   } else {
              return {
                ...prevSelected,
                [category]: [...categoryAccessories, accessory],
              };
            //}
          });
      }
  };

  const handleAccessoryDelete = (category, accessory, indexToRemove = null) => {
    console.log(selectedAccessories);
    // setSelectedAccessories((prevSelected) => ({
    //   ...prevSelected,
    //   [category]: prevSelected[category].filter((item) => item !== accessory),
    // }));
    setSelectedAccessories((prevSelected) => {
        const updatedAccessories = prevSelected[category].filter(
          (item, index) => !(item.name === accessory.name && (indexToRemove === null || index === indexToRemove))
        );
        return {
          ...prevSelected,
          [category]: updatedAccessories,
        };
      },()=>{ console.log(selectedAccessories);});
    setAccessoryIcons((prevIcons) => {
        const updatedIcons = { ...prevIcons };
        delete updatedIcons[accessory];
        return updatedIcons;
      });
  };
  const handleIconDrop = (category, accessory, indexToRemove, icon) => {
    console.log(selectedAccessories);
    if (icon) {
        if(category){
            // setSelectedAccessories((prevSelected) => ({
            //     ...prevSelected,
            //     [category]: prevSelected[category].filter((item) => item.name !== accessory.name),
            //   }));
            // setSelectedAccessories((prevSelected) => {
            //     const updatedAccessories = prevSelected[category].filter(
            //       (item, index) => !(item.name === accessory.name && (indexToRemove === null || index === indexToRemove))
            //     );
            //     return {
            //       ...prevSelected,
            //       [category]: updatedAccessories,
            //     };
            //   });
          }
      setAccessoryIcons((prevIcons) => ({
        ...prevIcons,
        [accessory]: icon,
      }));
    } else {
        if(category){
            setSelectedAccessories((prevSelected) => {
                const updatedAccessories = prevSelected[category].filter(
                  (item, index) => !(item.name === accessory.name && (indexToRemove === null || index === indexToRemove))
                );
                return {
                  ...prevSelected,
                  [category]: updatedAccessories,
                };
              },()=>{ console.log(selectedAccessories);});
            // setSelectedAccessories((prevSelected) => ({
            //     ...prevSelected,
            //     [category]: prevSelected[category].filter((item) => item.name !== accessory.name),
            //   }));
        }
      setAccessoryIcons((prevIcons) => {
        const updatedIcons = { ...prevIcons };
        delete updatedIcons[accessory];
        return updatedIcons;
      });
    }
  };

  return (
    <DndProvider backend={HTML5Backend}>
    <div className="w-full">
      <Stepper activeStep={activeStep} className="mb-6">
        {steps.map((label, index) => (
          <Step key={index}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      <div className="mt-2 mb-4">
        {activeStep === 0 && (
          <div>
            <Typography variant="h6" className="text-lg font-medium">Select material for your panel</Typography>
            <Button
              variant={selectedMaterial === 'Glass' ? 'contained' : 'outlined'}
              onClick={() => handleMaterialSelect('Glass')}
              className={`mr-2 mt-2 ${selectedMaterial === 'Glass' ? 'bg-blue-500 text-white' : 'bg-white text-blue-500 border border-blue-500'}`}
            >
              Glass
            </Button>
            <Button
              variant={selectedMaterial === 'Acrylic' ? 'contained' : 'outlined'}
              onClick={() => handleMaterialSelect('Acrylic')}
              className={`mt-2 ${selectedMaterial === 'Acrylic' ? 'bg-blue-500 text-white' : 'bg-white text-blue-500 border border-blue-500'}`}
            >
              Acrylic
            </Button>
          </div>
        )}
        {activeStep === 1 && (
          <div>
            <Typography variant="h6" className="text-lg font-medium">Select module size</Typography>
            <div className="flex flex-wrap">
              {moduleOptions.map((option, index) => (
                <Button
                  key={index}
                  variant={selectedModule === option.label ? 'contained' : 'outlined'}
                  onClick={() => handleModuleSelect(option.label)}
                  className={`mr-2 mt-2 ${selectedModule === option.label ? 'bg-blue-500 text-white' : 'bg-white text-blue-500 border border-blue-500'}`}
                >
                  {option.label}
                </Button>
              ))}
            </div>
            {selectedModule && (
              <div className={`mt-4 flex justify-center`}>
    <div id='panel' className={`${moduleOptions.find(option => option.label === selectedModule).className}`} style={{boxShadow: '0 0 10px #888',
    borderRadius: '10px',
    overflow: 'hidden'}}>
        <div className={`${moduleOptions.find(option => option.label === selectedModule).subclassName}`}></div>
    </div>
                {/* <img src={moduleOptions.find(option => option.label === selectedModule).image} alt={selectedModule} /> */}
              </div>
            )}
          </div>
        )}
        {activeStep === 2 && (
          <div className="flex">
            <div className="w-1/6">
              <Typography variant="h6" className="text-lg font-medium">Select an accessory</Typography>
              {Object.entries(accessories).map(([category, items], index) => (
                category == selectedModule ? <div key={index} className="mt-4">
                  <Typography variant="subtitle1" className="text-md font-medium">{category}</Typography>
                  {items.map((item, subIndex) => (
                    <Button
                      key={subIndex}
                      variant={selectedAccessories[category].includes(item) ? 'contained' : 'outlined'}
                      onClick={() => handleAccessorySelect(category, item)}
                      className={`block w-full text-left mt-2 ${selectedAccessories[category].includes(item) ? 'bg-blue-500 text-white' : 'bg-white text-blue-500 border border-blue-500'}`}
                    >
                      {item.name}
                    </Button>
                  ))}
                </div> : <></>
              ))}
            </div>
            <div className="w-5/6 flex justify-center items-center">
              <div className="relative">
              {selectedModule && (
              <div className={`mt-4 flex justify-center`}>
    <div id='panel' className={`${moduleOptions.find(option => option.label === selectedModule).className}`} style={{boxShadow: '0 0 10px #888',
    borderRadius: '10px',
    overflow: 'hidden'}}>
        <div className={`${moduleOptions.find(option => option.label === selectedModule).subclassName}`}>
        {Object.entries(selectedAccessories).flatMap(([category, accessories]) =>
                  accessories.map((accessory, index) => (
                    <div key={`${category}-${accessory}-${index}`} className="absolute" style={{position:'relative'}}>
                    <div style={{height:'100%'}}>
        {/* <img
          src={`https://via.placeholder.com/100?text=${accessory.name.replace(/\s+/g, '+')}`}
          alt={accessory}
          className="w-full h-full"
        /> */}
        <div className={`${accessory.className}`}>
            {accessory && accessory.nodes.map((node)=>
                <div className='nodeSize'>
                    {node.nodeClasses && node.nodeClasses.map((subnode) =>
                    <DroppableAccessory subnode={subnode} category={category} accessory={accessory} indexToRemove={index} onDrop={handleAccessoryDelete}/>
                    )}
                </div>
            )}
        </div>
        <Button
          size="small"
          onClick={() => handleAccessoryDelete(category, accessory, index)}
          className="absolute bottom-0 right-0 bg-red-500 text-white p-1"
        >
          <DeleteIcon className='delete' fontSize="small" />
        </Button>
      </div>
                    {/* <DroppableAccessory category={category} accessory={accessory} indexToRemove={index} onDrop={handleAccessoryDelete} /> */}
                      {/* <img
                        src={`https://via.placeholder.com/100?text=${accessory.replace(/\s+/g, '+')}`}
                        alt={accessory}
                        className="w-full h-full"
                      />
                      <Button
                        size="small"
                        onClick={() => handleAccessoryDelete(category, accessory)}
                        className="absolute bottom-0 right-0 bg-red-500 text-white p-1"
                      >
                        <DeleteIcon fontSize="small" />
                      </Button> */}
                    </div>
                  ))
                )}
        </div>
    </div>
                {/* <img src={moduleOptions.find(option => option.label === selectedModule).image} alt={selectedModule} /> */}
              </div>
            )}
                {/* {selectedModule && (
                  <img
                    src={moduleOptions.find(option => option.label === selectedModule).image}
                    alt={selectedModule}
                    className="w-full"
                  />
                )} */}
              </div>
            </div>
          </div>
        )}
        {activeStep >= 3 && (
          <div className="flex">
            <div className="w-1/6" style={{    overflow: 'auto',
    height: '40rem'}}>
              {activeStep == 3 && <Typography variant="h6" className="text-lg font-medium">Select icons</Typography>}
              {activeStep > 3 && <Typography variant="h6" className="text-lg font-medium">Select Color</Typography>}
              {activeStep > 3 && colors.map((color, index) => (
                  <div onClick={()=>{
                    console.log("I am in Color");
                    if(activeStep == 4){
                      let panel = document.getElementById('panel');
                      panel.style.backgroundColor = color;
                    } else {
                      let panel = document.getElementById('panel');
                      panel.style.border = `5px solid ${color}`;
                    }
                  }} key={index} className="mt-4" style={{backgroundColor:`${color}`,width: '5rem',height:'5rem',border:'2px solid black'}}>
                  </div>
                ))}
              {activeStep == 3 && Object.entries(icons).map(([category, items], index) => (
                  <div key={index} className="mt-4">
                    <Typography variant="subtitle1" className="text-md font-medium">{category}</Typography>
                    <div className="grid grid-cols-2 gap-2">
                      {items.map((icon, subIndex) => (
                        <DraggableIcon key={subIndex} icon={icon} index={subIndex} />
                      ))}
                    </div>
                  </div>
                ))}
            </div>
            <div className="w-5/6 flex justify-center items-center">
              <div className="relative">
              {selectedModule && (
              <div className={`mt-4 flex justify-center`}>
    <div ref={divRef} id='panel' className={`${moduleOptions.find(option => option.label === selectedModule).className}`} style={{boxShadow: '0 0 10px #888',
    borderRadius: '10px',
    overflow: 'hidden'}}>
        <div className={`${moduleOptions.find(option => option.label === selectedModule).subclassName}`}>
        {Object.entries(selectedAccessories).flatMap(([category, accessories]) =>
                  accessories.map((accessory, index) => (
                    <div key={`${category}-${accessory}-${index}`} className="absolute" style={{position:'relative'}}>
                    <div style={{height:'100%'}}>
        {/* <img
          src={`https://via.placeholder.com/100?text=${accessory.name.replace(/\s+/g, '+')}`}
          alt={accessory}
          className="w-full h-full"
        /> */}
        <div className={`${accessory.className}`}>
            {accessory && accessory.nodes.map((node)=>
                <div className='nodeSize'>
                    {node.nodeClasses && node.nodeClasses.map((subnode) =>
                      <DroppableAccessory subnode={subnode} category={category} accessory={accessory} indexToRemove={index} onDrop={handleIconDrop} activeStep={activeStep}/>
                    )}
                </div>
            )}
        </div>
        <Button
          size="small"
          onClick={() => handleAccessoryDelete(category, accessory, index)}
          className="absolute bottom-0 right-0 bg-red-500 text-white p-1"
        >
        { activeStep == 3 && <DeleteIcon className='delete' fontSize="small" />}
        </Button>
      </div>
                    {/* <DroppableAccessory category={category} accessory={accessory} indexToRemove={index} onDrop={handleIconDrop} /> */}
                      {/* <img
                        src={`https://via.placeholder.com/100?text=${accessory.replace(/\s+/g, '+')}`}
                        alt={accessory}
                        className="w-full h-full"
                      />
                      <Button
                        size="small"
                        onClick={() => handleAccessoryDelete(category, accessory)}
                        className="absolute bottom-0 right-0 bg-red-500 text-white p-1"
                      >
                        <DeleteIcon fontSize="small" />
                      </Button> */}
                    </div>
                  ))
                )}
                {/* {Object.entries(accessoryIcons).map(([accessory, icon], index) => (
                    <div key={`${accessory}-${index}-${icon}`} className="absolute" style={{position:'relative'}}>
                      <img
                        src={icon}
                        alt={accessory}
                        className="w-full h-full"
                      />
                      <Button
                        size="small"
                        onClick={() => handleIconDrop(null, accessory, null)}
                        className="absolute bottom-0 right-0 bg-red-500 text-white p-1"
                      >
                        <DeleteIcon fontSize="small" />
                      </Button>
                    </div>
                  ))} */}
        </div>
    </div>
                {/* <img src={moduleOptions.find(option => option.label === selectedModule).image} alt={selectedModule} /> */}
              </div>
            )}
                {/* {selectedModule && (
                  <img
                    src={moduleOptions.find(option => option.label === selectedModule).image}
                    alt={selectedModule}
                    className="w-full"
                  />
                )} */}
              </div>
            </div>
          </div>
        )}
        {/* {activeStep > 3 && (
          <Typography className="text-lg font-medium">{`This is ${steps[activeStep]}`}</Typography>
        )} */}
      </div>
      <div className="flex flex-row pt-2">
        <Button
          color="inherit"
          disabled={activeStep === 0}
          onClick={handleBack}
          className="mr-2"
        >
          Back
        </Button>
        <div className="flex-1" />
        <Button onClick={handleNext}>
          {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
        </Button>
        <Button onClick={handleDownload}>
          {activeStep === steps.length -1 ? 'Download as SVG' : ''}
        </Button>
      </div>
      {activeStep === steps.length && (
        <div className="mt-2">
          <Typography className="text-lg font-medium">All steps completed - you&apos;re finished</Typography>
          <Button onClick={handleReset}>Reset</Button>
        </div>
      )}
    </div>
    </DndProvider>
  );
};

export default StepperComponent;
